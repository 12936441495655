    export default {

      install(Vue, options) {


          Vue.prototype.$cv = function ( value) {
            //en base al tenant que recibo defino las clases, variables o textos a usar
            //let client = this.$keycloak.tokenParsed.foo_tenants[0];
            //let client = this.$keycloak.tokenParsed.tenantId;

            /*
            var vars = {
              iveco:{

                primary:'#54e920',
                principal:"green",
                btnEditar:"orange",
                btnActivo:"blue",
                btnInactivo:"red",
                btnEliminar:"red",
                btnVista:'primary',
                titleActivar:"Activar",
                titleDesactivar:"Desactivar",
                filterColor:"grey-lighten-2"
              },
              flota:{
                primary:'#ff9800',
                principal:"orange",
                btnEditar:"orange",
                btnActivo:"orange",
                btnInactivo:"orange",
                btnEliminar:"orange",
                btnVista:'orange',
                titleActivar:"Activar",
                titleDesactivar:"Desactivar"

              },
              acara:{
                primary:'#011F5B',
                color:"#6DB2D9",
                principal:"primary",
                btnEditar:"primary-2",
                btnActivo:"blue",
                btnInactivo:"red",
                btnEliminar:"red",
                btnVista:'primary',
                titleActivar:"Activar",
                titleDesactivar:"Desactivar",
                filterColor:"grey-lighten-2"
              }
            }*/
            var vars = {
                primary:'#011F5B',
                color:"#6DB2D9",
                principal:"primary",
                btnEditar:"primary-2",
                btnActivo:"blue",
                btnInactivo:"red",
                btnEliminar:"red",
                btnVista:'primary',
                titleActivar:"Activar",
                titleDesactivar:"Desactivar",
                filterColor:"grey-lighten-2"

            }

            this.$vuetify.theme.themes.dark.primary  = vars["primary"];
            this.$vuetify.theme.themes.light.primary = vars["primary"];
            this.$vuetify.theme.themes.dark.color    = vars["color"];
            this.$vuetify.theme.themes.light.color   = vars["color"];
              return vars[value];
          },

          Vue.prototype.$getPersonsTypes = function () {
            return [
              { id: 1, name: "FÍSICA" },
              { id: 2, name: "JURÍDICA" },
            ];
          }

      }
  }


