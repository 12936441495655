<template>
  <v-app light style="margin: 0px">
    <v-toolbar class="white pt-2 pb-5" height="80">
      <img src="images/logo.jpeg" alt="Logo" height="150px" />
    </v-toolbar>
    <v-main>
      <section>
        <!-- <v-parallax src="images/fleet-bckgr1.png" height="550">-->
        <v-parallax src="images/iveco_2.jpg" height="600">
          <v-layout column align-center justify-center>
            <v-row>
              <div class="col-md-8 offset-sm-9">
                <div class="container" style="height: auto">
                  <div class="row align-items-center">
                    <div class="col-md-12 ml-auto mr-auto mb-3 text-center">
                      <h3></h3>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 ml-auto mr-auto">
                      <!-- <two-factor-auth /> -->
                      <form
                        class="form"
                        autocomplete="off"
                        @submit.prevent="handleLogin"
                        method="post"
                      >
                        <v-col cols="12" md="12" sm="12">
                          <base-material-card
                            color="primary"
                            class="px-5 py-3 pb-0"
                          >
                            <template v-slot:heading>
                              <div class="text-h5 font-weight-light">
                                Login - Inicie sesion
                              </div>
                            </template>

                            <v-card-text>
                              <div class="card-body">
                                <p class="card-description text-center">
                                  Sistema web inteligente de administracion y
                                  gestión integral de flota de vehiculos.
                                </p>

                                <div class="bmd-form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                        <i class="material-icons">email</i>
                                      </span>
                                    </div>
                                    <input
                                      type="email"
                                      name="email"
                                      v-model="formData.email"
                                      class="form-control"
                                      style="background-color: #eaeaea"
                                      placeholder="mail@mail.com"
                                      required
                                    />
                                  </div>
                                </div>
                                <div class="bmd-form-group mt-3">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                        <i class="material-icons"
                                          >lock_outline</i
                                        >
                                      </span>
                                    </div>
                                    <input
                                      type="password"
                                      name="password"
                                      v-model="formData.password"
                                      id="password"
                                      class="form-control"
                                      style="background-color: #eaeaea"
                                      placeholder="contraseña"
                                      required
                                    />
                                  </div>

                                  <div
                                    v-if="formData.error"
                                    id="password-error"
                                    class="error text-danger pl-3"
                                    for="password"
                                    style="display: block"
                                  >
                                    <strong
                                      ><p>
                                        {{ formData.error }}
                                      </p></strong
                                    >
                                  </div>
                                </div>
                                <!--
                                                <div class="form-check mr-auto ml-3 mt-3">
                                                  <label class="form-check-label">
                                                      <input class="form-check-input" type="checkbox" name="remember">Recordarme
                                                      <span class="form-check-sign">
                                                      <span class="check"></span>
                                                      </span>
                                                  </label>
                                                </div>
                                                -->
                                <div class="form-check mr-auto ml-3 mt-3">
                                  <label class="form-check-label">
                                    <a href="/forget-password"
                                      >Olvidó su contraseña?</a
                                    >
                                  </label>
                                </div>
                              </div>
                              <div
                                class="card-footer justify-content-center md-6"
                              >
                                <v-row>
                                  <v-col cols="12" md="6" sm="6">
                                    <v-btn
                                      type="submit"
                                      class="btn btn-primary btn-link btn-sm sm-4"
                                      :color="
                                        $vuetify.theme.themes.light.secondary
                                      "
                                      >Iniciar Sesión</v-btn
                                    >
                                  </v-col>
                                  <!-- <v-col cols="12" md="5" sm="5" class="ml-5">
                                    <v-btn
                                      @click="recargarApp()"
                                      color="primary"
                                      class="btn btn-sm sm-4"
                                      >Recargar</v-btn
                                    >
                                  </v-col>-->
                                </v-row>
                              </div>
                              <v-row>
                                <v-col class="text-right p-0 mt-4">
                                  <a
                                    class="text-right text-secondary"
                                    @click="recargarApp()"
                                    ><small
                                      >Borrar las Cookies y recargar la
                                      app</small
                                    ></a
                                  >
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </base-material-card>
                        </v-col>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </v-row>
          </v-layout>
        </v-parallax>
      </section>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      user: {},
      formData: {
        email: null,
        password: null,
        error: null,
      },
      processing: false,
    };
  },
  mounted() {
    //console.log(this);
  },
  /*created(){
    this.$store.dispatch("destroyToken").then(response => {
      this.me();
    });

  },*/
  methods: {
    ...mapActions({
      signIn: "login",
      signOut: "logout",
    }),
    /*async login(){
            this.processing = true
            await axios.get('/sanctum/csrf-cookie')
            await axios.post('/login',this.auth).then(({data})=>{
                this.signIn()
            }).catch(({response:{data}})=>{
                alert(data.message)
            }).finally(()=>{
                this.processing = false
            })
        },*/
    async handleLogin() {
      Swal.fire({
        title: "Aguarde un instante!",
        icon: "info",
        //showCloseButton: true,
        text: "Estamos procesando información",
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
      await axios.get("/sanctum/csrf-cookie");
      await axios
        .post("/login", this.formData)
        .then(({ data }) => {
          Swal.close();
          switch (data.codeStatus) {
            case 200:
              this.signIn();
              return;
              break;
            case 401:
              Swal.fire(
                "Inicio de Sesion",
                "Usuario o Contraseña erróneas. Verifique la información e intente nuevamente",
                "warning"
              );
              return;
              break;
            case 500:
              if (data.password_expired) {
                Swal.close();
                this.$router.push({
                  name: "passwordExpiration",
                });
                /*
                Swal.fire(
                  "Atención!",
                  'Su Usuario se encuentra Bloqueado. Recupere su clave desde la opción "Olvido su contraseña? o comuníquese con el administrador"',
                  "error"
                );*/
              } else {
                Swal.close();
                Swal.fire(
                  "Error de Inicio de Sesión",
                  "Verifique las credenciales ingresadas",
                  "error"
                );
                return;
              }
              break;
          }
        })
        .catch(({ response: { data } }) => {
          //Swal.close();
          Swal.fire(
            "Error de Inicio de Sesión",
            "Verifique las credenciales ingresadas",
            "error"
          );
        })
        .finally(() => {
          //Swal.close();
          this.processing = false;
        });
      //axios.get('/sanctum/csrf-cookie').then(() => {
      //  axios.post('/login', this.formData).then(res => {
      //axios.get("/api/user", {}).then(response => {
      //this.signIn();
      //let user = response.data;
      //this.$store.commit('SET_DATA_USER', user);
      /*if(user.agencia_id){
                this.$router.push({ name: "pendientes" });
              }
              else{
                this.$router.push({ name: "dashboard" });
              }*/
      //});
      //}).catch(error => {
      //this.formData.error = error.data;
      //}); // credentials didn't match
      //});
    },
    me() {
      axios
        .get("/api/user")
        .then((res) => {
          this.user = res.data;
        })
        .catch(() => {});
    },
    logout() {
      axios
        .post("/logout")
        .then(() => {
          this.user = {};
        })
        .catch((error) => {
          this.formData.error = error.response.data;
        });
    },
    login() {
      this.$store
        .dispatch("retrieveToken", {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          this.$router.push({ name: "dashboard" });
        })
        .catch((error) => {
          this.error = error.response.data;
        });
    },
    recargarApp() {
      var cookies = document.cookie.split("; ");
      for (var c = 0; c < cookies.length; c++) {
        var d = window.location.hostname.split(".");
        while (d.length > 0) {
          var cookieBase =
            encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
            "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
            d.join(".") +
            " ;path=";
          var p = location.pathname.split("/");
          document.cookie = cookieBase + "/";
          while (p.length > 0) {
            document.cookie = cookieBase + p.join("/");
            p.pop();
          }
          d.shift();
        }
      }
      window.location.reload();
    },
  },
};
</script>
