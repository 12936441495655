export const apiRoute = Object.freeze({
    mensajes:"api/mensajes",
    mensajes_enviados:"api/mensajes/usuario/enviados",
    mensajes_recibidos:"api/mensajes/usuario/recibidos",
    noticias:"api/mensajes/usuario/noticias",
    mensajes_recibidos_leidos:"api/mensajes/usuario/recibidosleidos",
    mensajes_leido:"api/mensajes/marcarleido",
    usuarios:"api/keycloak/usuarios",
    aseguradoras:"api/aseguradoras",
    aseguradoras_enable: "api/aseguradoras/changestatus",
    aseguradoras_pdf: "api/aseguradoras/pdf",
    concesionarios:"api/concesionarios",
    concesionarios_enable: "api/concesionarios/changestatus",
    concesionarios_aseguradoras:"api/concesionarios/concesionarios_aseguradoras",
    concesionarios_aseguradoras_enable: "api/concesionarios/concesionarios_aseguradoras/changestatus",
    aseguradorasByConcesionario: "api/concesionarios/concesionarios_aseguradoras/getAseguradorasByConcesionario",
    deleteAseguradorasByConcesionario: "api/concesionarios/concesionarios_aseguradoras/deleteAseguradorasByConcesionario",
    tipo_tramites:"api/tipo_tramites",
    tipo_vehiculos:"api/tipo_vehiculos",
    tipo_de_usos:"api/tipo_de_usos",
    tipo_de_usos_enable: "api/tipo_de_usos/changestatus",
    tipo_certificados: "api/tipo_certificados",
    tipo_certificados_enable: "api/tipo_certificados/changestatus",
    tipo_operaciones: "api/tipo_operaciones",
    tipo_operaciones_enable: "api/tipo_operaciones/changestatus",
    marcas: "api/marcas",
    marcas_enable: "api/marcas/enable",
    modelos: "api/modelos",
    modelos_marca: "api/getModelosMarca",
    users: "api/users",
    getUser:"api/getUser",
    marcas_modelos: "api/marcas_modelos",
    marcas_modelos_enable: "api/marcas_modelos/enable",
    marcas_upload:"api/marcas_upload",
    formularios: "api/formularios",
    formulariosSaveSeccion: "api/formularios/seccion",
    getFormularios: "api/getFormularios",
    showFormulario: "api/formularios/showFormulario",
    formularios_enable: "api/formularios/enable",
    formulario01Pdf: "api/formularios/pdf/formulario01",
    formulario01Zip: "api/formularios/pdf/getAllF01",
    formulario12Pdf: "api/formularios/pdf/formulario12",
    ddjjPdf: "api/formularios/pdf/ddjj",
    formulario_enmienda: "api/formularios/pdf/enmienda",
    titulares: "api/titulares",
    titularesRegisterForm: "api/titulares/registerForm",
    userAuthRoute: "api/auth",
    identifiertypes: process.env.VUE_APP_API_URL_PERSONS+"persons/identifiertypes",
    genders: process.env.VUE_APP_API_URL_PERSONS+"persons/genders",
    maritalstatuses: process.env.VUE_APP_API_URL_PERSONS+"persons/maritalstatuses",
    persons: process.env.VUE_APP_API_URL_PERSONS+"persons",
    personsAggregator: process.env.VUE_APP_API_URL_PERSONS+"personsaggregator",
    personRelationship: process.env.VUE_APP_API_URL_PERSONS+"persons/relationships",
    //paises: "https://restcountries.com/v3.1/all",
    paises: "api/domicilios/paises",
  });
