import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

const theme = {

  primary: '#4CAF50', //#105cfa //4CAF50//
  secondary: '#3be5cf', //9C27b0//3be5cf
  color: "#FFFFFF",
  //accent: '#9C27b0',
  info: '#00CAE3',
  background: "#FF0000",
  danger: "#fa1010"
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
