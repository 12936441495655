  export default {

    install(Vue, options) {


        Vue.prototype.can = function ( value) {
          // let client = this.$keycloak.tokenParsed.foo_tenants[0];
          if (this.$keycloak.hasResourceRole(value)) {
            return true;
          }
          else
          return false;

        }
        Vue.prototype.is = function ( value) {
          if (this.$keycloak.hasResourceRole(value)) {
            return true;
          }
          else
          return false;

        }

    }
  }


