<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card-text>
      <v-stepper v-model="e6" :vertical="vertical">
        <v-stepper-header>
          <v-stepper-step
            key="1"
            :editable="e6 > 1"
            :complete="e6 > 1"
            step="1"
          >
            Tramite
            <small>Info. general <br />del trámite</small>
          </v-stepper-step>
          <v-divider key="2"></v-divider>

          <v-stepper-step :editable="e6 > 2" :complete="e6 > 2" step="2">
            Titulares
            <small>Info. de Titulares y <br />Condóminos</small>
          </v-stepper-step>
          <v-divider key="3"></v-divider>
          <v-stepper-step :editable="e6 > 3" :complete="e6 > 3" step="3">
            Vehiculo
            <small>Datos del Vehiculo<br />adquirido</small>
          </v-stepper-step>
          <v-divider key="4"></v-divider>
          <v-stepper-step :editable="e6 > 4" :complete="e6 > 4" step="4">
            Adquisición
            <small>Info. del modo y <br />tipo de adquisición</small>
          </v-stepper-step>
          <v-divider key="5"></v-divider>
          <v-stepper-step :editable="e6 > 5" :complete="e6 > 5" step="5">
            Seguro
            <small>Info. sobre el seguro <br />contratado</small>
          </v-stepper-step>
          <v-divider key="6"></v-divider>
          <v-stepper-step :editable="e6 > 6" :complete="e6 > 6" step="6">
            Registro Seccional
            <small>Domicilio del titular o <br />acreedor prendario</small>
          </v-stepper-step>
          <v-divider key="7"></v-divider>
          <v-stepper-step :editable="e6 > 7" :complete="e6 > 7" step="7">
            Vista Previa
            <small
              >Verificación de los<br />
              datos cargados</small
            >
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <datos-tramite
              ref="datosTramite"
              :tramite="datosTramite"
              :tipoF01="tipoF01"
              :idTramite="editIndex"
              @send-message="handleStep"
              :key="keyDatosTramite"
              :keyProp="keyDatosTramite"
            ></datos-tramite>
          </v-stepper-content>
          <v-stepper-content step="2">
            <datos-titulares
              ref="datosTitulares"
              :datosTitulares="datosTitulares"
              :titulares="titulares"
              :cedulas="cedulas"
              :datosAutorizados="datosAutorizados"
              :idTramite="editIndex"
              :key="keyDatosTitulares"
              @send-message="handleStep"
              v-if="this.keyDatosTitulares != 0"
              :tramite="formVp"
            ></datos-titulares>
          </v-stepper-content>
          <v-stepper-content step="3">
            <datos-vehiculo
              ref="datosVehiculo"
              :datosVehiculo="datosVehiculo"
              :tipoF01="tipoF01"
              :idTramite="editIndex"
              :tramite="datosTramite"
              @send-message="handleStep"
              v-bind:readonly="readonly"
              :key="keyDatosVehiculo"
              v-if="this.keyDatosVehiculo != 0"
            ></datos-vehiculo>
          </v-stepper-content>
          <v-stepper-content step="4">
            <datos-adquisicion
              ref="datosAdquisicion"
              :idTramite="editIndex"
              :key="keyDatosAdquisicion"
              @send-message="handleStep"
              :datosAdquisicion="datosAdquisicion"
              v-if="this.keyDatosAdquisicion != 0"
            ></datos-adquisicion>
          </v-stepper-content>
          <v-stepper-content step="5">
            <datos-seguro
              ref="datosSeguro"
              :seguro="datosSeguro"
              :idTramite="editIndex"
              :key="keyDatosSeguro"
              v-if="this.keyDatosSeguro != 0"
              @send-message="handleStep"
            ></datos-seguro>
          </v-stepper-content>
          <v-stepper-content step="6">
            <datos-registro
              ref="datosRegistro"
              :idTramite="editIndex"
              :key="keyDatosRegistro"
              @send-message="handleStep"
              v-if="this.keyDatosRegistro != 0"
              :datosRegistro="datosRegistro"
            ></datos-registro>
          </v-stepper-content>
          <v-stepper-content step="7">
            <datos-vp
              ref="datosVp"
              :key="keyDatosVp"
              :formData="formVp"
              @send-message="handleStep"
            ></datos-vp>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-form>
</template>

<script>
function title() {
  return "Formulario 01";
}

export default {
  components: {
    DatosTramite: () => import("./DatosTramite"),
    DatosTitulares: () => import("./DatosTitulares"),
    DatosVehiculo: () => import("./DatosVehiculo"),
    DatosAdquisicion: () => import("./DatosAdquisicion"),
    DatosSeguro: () => import("./DatosSeguro"),
    DatosRegistro: () => import("./DatosRegistro"),
    DatosVp: () => import("./DatosVp"),
  },
  props: {
    formDataWizard: { Object, default: null },
    tipoF01: { String, default: null },
    readonly: { type: Boolean, default: false },
  },
  data: (vm) => ({
    title: title(),
    e6: 1,
    steps: 6,
    vertical: false,
    route: "formularios",
    updateSeccion: "formulariosSaveSeccion",
    routeTitulares: "titularesRegisterForm",
    panel: [0],
    form: { anio: 0 },
    valid: true,
    checkbox: false,
    titleToolbar: "",
    visible: true,
    dialog: false,
    editIndex: null,
    editedItem: {},
    datosTramite: {},
    datosTitulares: {},
    datosVehiculo: {},
    datosAdquisicion: {},
    datosSeguro: {},
    datosRegistro: {},
    datosAutorizados: [],
    cedulas: 0,
    formTitulares: { formulario_id: "", titulares: [] },
    keyDatosTramite: 0,
    keyDatosTitulares: 0,
    keyDatosVehiculo: 0,
    keyDatosAdquisicion: 0,
    keyDatosSeguro: 0,
    keyDatosRegistro: 0,
    keyDatosVp: 0,
    formVp: {
      tramite: {},
      titulares: {},
      vehiculo: {},
      adquisicion: {},
      seguro: {},
      registro: {},
    },
    titulares: 0,
    userForm: {},
    routeUser: "userAuthRoute",
  }),
  watch: {},
  computed: {
    formTitle() {
      return !this.tramite
        ? "Registrar " + this.title + " - " + this.tipoF01
        : "Editar " + this.title + " - " + this.tipoF01;
    },
  },
  methods: {
    add(items) {
      let numero = 0;
      let funcion = "";
      let personas = [];
      for (let i = 0; i < Number(items); i++) {
        if (i == 0) {
          numero = 1;
          funcion = "Titular N° " + numero;
        } else {
          //if (i % 2 == 0) {
          numero++;
          funcion = "Condómino N° " + numero;
          /*} else {
                                      funcion = "Condómino N° " + numero;
                                    }*/
        }

        let tipoPersona = "";

        if (this.editedItem.sociedad_hecho == "S" && i == 0) {
          tipoPersona = 2;
        }

        personas.push({
          id: "",
          personType: tipoPersona,
          tipo_persona: "",
          titular_principal: false,
          tipo_documento: "",
          cuit: "",
          pais_dni: "",
          nacionalidad: "",
          ocupacion: "",
          telefono: "",
          mail: "",
          fecha_nacimiento: "",
          lugar_nacimiento: "",
          estado_civil: "",
          formulario_01: "",
          tipo_documento: "",
          documento: "",
          sexo: "",
          otorgada_por: "",
          numero_inscripcion: "",
          fecha_inscripcion: "",
          apoderados: "",
          domicilioLegal: {},
          domicilioReal: {},
          numero: numero,
          index: Number(i),
          funcion: funcion,
          activity: "",
          lastName: "",
          firstName: "",
          porcentaje: "",
        });

        if (this.editIndex > 0) {
          if (typeof this.$refs.datosTitulares !== "undefined") {
            if (
              typeof this.$refs.datosTitulares.$refs.titulares.personas[i] !==
              "undefined"
            ) {
              personas[i] =
                this.$refs.datosTitulares.$refs.titulares.personas[i];
            }
          }
        }
      }

      if (this.editIndex > 0) {
        if (typeof this.$refs.datosTitulares !== "undefined") {
          this.$refs.datosTitulares.$refs.titulares.personas = personas;
        }
      }
      this.datosTitulares = personas;
    },
    async saveDatosComplete() {
      const valid = await new Promise((resolve) => {
        let vm = this;
        let data = {};
        data.datos_titulares = vm.formTitulares;

        vm.$axiosApi
          .put(vm.updateSeccion, vm.editIndex, data)
          .then((r) => {
            resolve(true);
          })
          .catch((e) => {
            resolve(e);
          });
      });

      return valid;
    },

    async handleStep(value, step = false, formStep = false) {
      /*console.log(value);
                                                                    console.log(step);*/
      if (!step) {
        this.e6 = value;
        return;
      }
      //console.log(formStep);
      switch (step) {
        case "1": //registro de tramite
          if (formStep.sociedad_hecho == "S" && formStep.titulares < 3) {
            Swal.alertError(
              "Atención",
              "Al seleccionar Sociedad de hecho: 'SI' la cantidad de titulares debe ser al menos 3"
            );
            return;
          }
          this.editedItem = formStep;
          let titleSwal = this.editIndex ? "Actualizar" : "Registrar";
          let titleAlert = this.editIndex ? "Actualizando" : "Registrando";
          let noticeAlert = "";
          //this.e6 = value;
          //console.log(Number(formStep.titulares), Number(this.titulares));
          if (Number(formStep.titulares) < Number(this.titulares)) {
            noticeAlert =
              "<b>Atención!</b>. Si reduce la cantidad de titulares <b>(de " +
              Number(this.titulares) +
              " a " +
              Number(formStep.titulares) +
              ")</b>, se perderán los datos previamente cargados en la siguiente sección. Desea continuar con la carga?";
          }

          //console.log("this.editIndex");
          //console.log(this.editIndex);
          //this.keyDatosTitulares = this.keyDatosTitulares + 1;
          Swal.fire(
            Swal.objQuestion(
              "<b>" + titleSwal + " Datos del Tramite?</b>",
              noticeAlert
                ? noticeAlert
                : "Desea " +
                    titleSwal +
                    " el formulario para el Certificado <b>" +
                    this.editedItem.numero_certificado +
                    "</b>, Número de Chasis: " +
                    "<b>" +
                    this.editedItem.chasis +
                    "</b> "
            )
          ).then((result) => {
            if (result.isConfirmed) {
              /*console.log(Number(formStep.titulares));
                                    console.log(Number(this.titulares));
                                    console.log(this.datosTitulares.length);*/
              if (Number(formStep.titulares) != Number(this.titulares)) {
                if (Number(formStep.titulares) < Number(this.titulares)) {
                  //si la cantidad es menor a la actual, borro los ultimos titulares que habia
                  //this.datosTitulares = [];
                  let posFin = Number(this.titulares) - 1;
                  this.datosTitulares =
                    this.$refs.datosTitulares.$refs.titulares.personas;
                  this.datosTitulares.splice(
                    posFin,
                    Number(formStep.titulares)
                  );
                  this.add(Number(formStep.titulares));
                  this.keyDatosTitulares = this.keyDatosTitulares + 1;
                } else {
                  //si la cantidad es mayor a la actual, mantengo los q estan y agrego el resto

                  let cantidad =
                    Number(formStep.titulares) - Number(this.titulares);

                  this.add(Number(formStep.titulares));
                  this.keyDatosTitulares = this.keyDatosTitulares + 1;
                }
              } else if (this.datosTitulares.length <= 0) {
                let cantidad =
                  Number(formStep.titulares) - Number(this.titulares);

                this.add(Number(formStep.titulares));
                this.keyDatosTitulares = this.keyDatosTitulares + 1;
              }

              this.titulares = Number(formStep.titulares);
              Swal.alertGetInfo(titleAlert + " información del tramite");
              //guardo los datos en el form de vp
              this.formVp.tramite = formStep;

              if (this.editIndex) {
                let data = Object.assign({}, this.editedItem);
                delete data.datos_formulario;
                delete data.datos_seguro;
                delete data.datos_vehiculo;
                delete data.datos_adquisicion;

                this.$axiosApi
                  .put(this.route, this.editIndex, data)
                  .then((r) => {
                    if (typeof r !== "undefined" && r !== null) {
                      this.editedItem.id = r.data.data.id;
                      this.keyDatosVp = +1;
                      Swal.close();
                      Swal.fire(
                        Swal.objSuccess(
                          "Se ha actualizaron los datos del formulario 01 " +
                            this.tipoF01,
                          "Puede continuar cargando la información del trámite en la siguiente sección"
                        )
                      ).then((result) => {
                        if (result.isConfirmed) {
                          this.e6 = value;
                          this.keyDatosTitulares += 1;
                        }
                      });
                      /*
                                                                                    this.saveDatosComplete().then((result) => {
                                                                                      if (result === true) {
                                                                                        this.keyDatosVp = +1;
                                                                                        Swal.close();
                                                                                        Swal.fire(
                                                                                          Swal.objSuccess(
                                                                                            "Se ha actualizaron los datos del formulario 01 " +
                                                                                              this.tipoF01,
                                                                                            "Puede continuar cargando la información del trámite en la siguiente sección"
                                                                                          )
                                                                                        ).then((result) => {
                                                                                          if (result.isConfirmed) {
                                                                                            this.e6 = value;
                                                                                          }
                                                                                        });
                                                                                      } else {
                                                                                        Swal.close();
                                                                                        Swal.alertError(
                                                                                          "Error al actualizar Formulario 01",
                                                                                          "Ha ocurrido un error al intentar actualizar los datos del formulario"
                                                                                        );
                                                                                      }
                                                                                    });*/
                    } else {
                      Swal.close();
                      Swal.alertError(
                        "Error al actualizar Formulario 01",
                        "Ha ocurrido un error al intentar actualizar los datos del formulario"
                      );
                    }
                  })
                  .catch((e) => {
                    Swal.close();
                    console.log(e);
                    this.snackbar = true;
                    this.text =
                      "Error al ingresar los datos. Error: " + e.message;
                    this.color = "error";
                  });
              } else {
                this.$axiosApi
                  .post(this.route, this.editedItem)
                  .then((r) => {
                    if (typeof r !== "undefined" && r !== null) {
                      //console.log(this.editedItem);
                      this.editedItem.id = r.data.data.id;
                      this.editIndex = r.data.data.id;
                      //guardo los datos en el form de vp
                      //this.formVp.tramite = this.editedItem;
                      this.keyDatosVp = +1;
                      Swal.close();
                      Swal.fire(
                        Swal.objSuccess(
                          "Se ha registrado un nuevo formulario 01 " +
                            this.tipoF01,
                          "Puede continuar cargando la información del trámite en la siguiente sección"
                        )
                      ).then((result) => {
                        if (result.isConfirmed) {
                          this.e6 = value;
                          this.keyDatosTitulares += 1;
                        }
                      });

                      //console.log(r.data);
                    } else {
                      Swal.close();
                      Swal.alertError(
                        "Error al registrar Formulario 01",
                        "Ha ocurrido un error al intentar registrar los datos del formulario"
                      );
                    }
                  })
                  .catch((e) => {
                    Swal.close();
                    console.log(e);
                    this.snackbar = true;
                    this.text =
                      "Error al ingresar los datos. Error: " + e.message;
                    this.color = "error";
                  });
              }
            }
          });

          //this.componentKeyFinish += 1;
          break;
        case "2": //registro de titulares
          if (formStep) {
            this.formTitulares = {
              formulario_id: this.editedItem.id,
              titulares: formStep.titulares,
              cedulas: formStep.cedulas,
              autorizados: formStep.autorizados,
            };

            var porcentajeTotal = 0;
            var titular_principal = false;

            for (let i = 0; i < this.formTitulares.titulares.length; i++) {
              var p = !isNaN(
                parseFloat(this.formTitulares.titulares[i].porcentaje)
              )
                ? parseFloat(this.formTitulares.titulares[i].porcentaje)
                : 0;

              porcentajeTotal = porcentajeTotal + p;
              if (this.formTitulares.titulares[i].titular_principal === true) {
                titular_principal = true;
              }
            }

            if (porcentajeTotal != 100) {
              Swal.alertError(
                "Error al registrar los Titulares ",
                "La suma de los porcentajes de los titulares es " +
                  porcentajeTotal +
                  "% y debe ser 100 %"
              );
            } else if (!titular_principal) {
              Swal.alertError(
                "Error al registrar los Titulares ",
                "Debe seleccionar un titular principal"
              );
            } else {
              Swal.fire(
                Swal.objQuestion(
                  "<b>Registrar Datos de los titulares?</b>",
                  "Desea asociar el formulario con los titulares"
                )
              ).then((result) => {
                if (result.isConfirmed) {
                  //guardo los datos en el form de vp
                  this.formVp.titulares = this.formTitulares;
                  Swal.alertGetInfo("Registrando datos de titulares");

                  this.$axiosApi
                    .post(this.routeTitulares, this.formTitulares)
                    .then((r) => {
                      if (typeof r !== "undefined" && r !== null) {
                        this.keyDatosVp = +1;
                        Swal.close();
                        Swal.fire(
                          Swal.objSuccess(
                            "Se han registrado la información de los titulares ",
                            "Puede continuar cargando la información del trámite en la siguiente sección"
                          )
                        ).then((result) => {
                          if (result.isConfirmed) {
                            this.e6 = value;
                            this.keyDatosVehiculo = this.keyDatosVehiculo + 1;
                          }
                        });
                      } else {
                        Swal.close();
                        Swal.alertError(
                          "Error al registrar los Titulares ",
                          "Ha ocurrido un error al intentar registrar los titulares"
                        );
                      }
                    })
                    .catch((e) => {
                      Swal.close();
                      console.log(e);
                      Swal.alertError(
                        "Error al registrar los Titulares ",
                        "Ha ocurrido un error al intentar registrar los titulares"
                      );
                    });
                }
              });
            }
          }
          break;
        case "3": //registro de datos de vehiculo
          //if (formStep) {

          Swal.fire(
            Swal.objQuestion(
              "<b>Registrar Datos del Vehículo?</b>",
              "Confirma que los datos del vehículo son correctos?"
            )
          ).then((result) => {
            if (result.isConfirmed) {
              this.formVp.vehiculo = formStep;
              this.datosVehiculo = formStep;
              let data = {};
              data.datos_vehiculo = formStep;
              Swal.alertGetInfo("Registrando información del vehiculo");
              if (this.editedItem.id) {
                this.$axiosApi
                  .put(this.updateSeccion, this.editedItem.id, data)
                  .then((r) => {
                    if (typeof r !== "undefined" && r !== null) {
                      this.editedItem.id = r.data.data.id;
                      this.keyDatosVp = +1;
                      Swal.close();

                      Swal.fire(
                        Swal.objSuccess(
                          "Se ha registrado la informacion del vehículo ",
                          "Puede continuar cargando la información del trámite en la siguiente sección"
                        )
                      ).then((result) => {
                        if (result.isConfirmed) {
                          this.e6 = value;
                          this.keyDatosAdquisicion += 1;
                        }
                      });
                    } else {
                      Swal.close();
                      Swal.alertError(
                        "Error al registrar los Datos del vehiculo ",
                        "Ha ocurrido un error al intentar registrar información del vehiculo"
                      );
                    }
                  })
                  .catch((e) => {
                    Swal.close();
                    console.log(e);
                    this.snackbar = true;
                    this.text =
                      "Error al ingresar los datos. Error: " + e.message;
                    this.color = "error";
                  });
              } else {
                Swal.close();
                console.log(e);
                this.snackbar = true;
                this.text = "Error al ingresar los datos. Error: " + e.message;
                this.color = "error";
              }
            }
          });
          //}
          break;
        case "4":
          //this.editedItem = formStep;

          Swal.fire(
            Swal.objQuestion(
              "<b>Registrar Datos de la adquisición?</b>",
              "Confirma que los datos de adquisición son correctos?"
            )
          ).then((result) => {
            if (result.isConfirmed) {
              this.formVp.adquisicion = formStep;
              Swal.alertGetInfo("Registrando información de adquisición");

              let data = {};
              data.datos_adquisicion = formStep;
              //para actualizar el objeto
              this.datosAdquisicion = formStep;
              if (this.editedItem.id) {
                this.$axiosApi
                  .put(this.updateSeccion, this.editedItem.id, data)
                  .then((r) => {
                    if (typeof r !== "undefined" && r !== null) {
                      this.editedItem.id = r.data.data.id;
                      this.keyDatosVp = +1;
                      Swal.close();
                      Swal.fire({
                        title:
                          "Se ha registrado la informacion de la adquisición ",
                        icon: "success",
                        html: "Puede continuar cargando la información del trámite en la siguiente sección",
                        showCloseButton: false,
                        showCancelButton: false,
                        focusConfirm: true,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Aceptar",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          this.e6 = value;
                          this.keyDatosSeguro = this.keyDatosSeguro + 1;
                        }
                      });
                    } else {
                      Swal.close();
                      Swal.alertError(
                        "Error al registrar los Datos de adjudicación ",
                        "Ha ocurrido un error al intentar registrar los datos de adjudicación"
                      );
                    }
                  })
                  .catch((e) => {
                    Swal.close();
                    console.log(e);
                    this.snackbar = true;
                    this.text =
                      "Error al ingresar los datos. Error: " + e.message;
                    this.color = "error";
                  });
              } else {
                Swal.close();
                console.log(e);
                this.snackbar = true;
                this.text = "Error al ingresar los datos. Error: " + e.message;
                this.color = "error";
              }
            }
          });

          //this.componentKeyFinish += 1;
          break;
        case "5":
          //this.editedItem = formStep;
          console.log(formStep);
          Swal.fire(
            Swal.objQuestion(
              "<b>Registrar Datos del seguro?</b>",
              "Confirma que los datos del seguro son correctos?"
            )
          ).then((result) => {
            if (result.isConfirmed) {
              Swal.alertGetInfo("Registrando información del seguro");
              this.formVp.seguro = formStep;
              //para actualizar el objeto
              this.datosSeguro = formStep;
              let data = {};
              data.datos_seguro = formStep;
              if (this.editedItem.id) {
                this.$axiosApi
                  .put(this.updateSeccion, this.editedItem.id, data)
                  .then((r) => {
                    if (typeof r !== "undefined" && r !== null) {
                      this.editedItem.id = r.data.data.id;
                      this.keyDatosVp = +1;
                      Swal.close();
                      Swal.fire(
                        Swal.objSuccess(
                          "Se ha registrado la información del seguro ",
                          "Puede continuar cargando la información del trámite en la siguiente sección"
                        )
                      ).then((result) => {
                        if (result.isConfirmed) {
                          this.e6 = value;
                          this.keyDatosRegistro += 1;
                        }
                      });
                    } else {
                      Swal.close();

                      Swal.alertError(
                        "Error al registrar los Datos del seguro",
                        "Ha ocurrido un error al intentar registrar la información del seguro"
                      );
                    }
                  })
                  .catch((e) => {
                    Swal.close();
                    console.log(e);
                    this.snackbar = true;
                    this.text =
                      "Error al ingresar los datos. Error: " + e.message;
                    this.color = "error";
                  });
              } else {
                Swal.close();
                console.log(e);
                this.snackbar = true;
                this.text = "Error al ingresar los datos. Error: " + e.message;
                this.color = "error";
              }
            }
          });

          //this.componentKeyFinish += 1;
          break;
        case "6":
          Swal.fire(
            Swal.objQuestion(
              "<b>Registrar Datos del registro?</b>",
              "Confirma que los datos del registro son correctos?"
            )
          ).then((result) => {
            if (result.isConfirmed) {
              Swal.alertGetInfo("Registrando información del registro");

              this.formVp.registro = formStep;
              //para actualizar el objeto
              this.datosRegistro = formStep;
              let data = {};
              data.datos_registro = formStep;

              if (this.editedItem.id) {
                this.$axiosApi
                  .put(this.updateSeccion, this.editedItem.id, data)
                  .then((r) => {
                    if (typeof r !== "undefined" && r !== null) {
                      this.editedItem.id = r.data.data.id;
                      this.keyDatosVp = this.keyDatosVp + 1;
                      this.e6 = value;
                      Swal.close();

                      Swal.fire(
                        Swal.objSuccess(
                          "Se ha registrado la información del Registro Seccional",
                          "Puede visualizar la información completa del trámite en la siguiente sección"
                        )
                      );
                    } else {
                      Swal.close();

                      Swal.alertError(
                        "Error al Registrar Datos de Registro Seccional",
                        "Ha ocurrido un error al intentar registrar la información del registro seccional"
                      );
                    }
                  })
                  .catch((e) => {
                    Swal.close();
                    console.log(e);
                    this.snackbar = true;
                    this.text =
                      "Error al ingresar los datos. Error: " + e.message;
                    this.color = "error";
                  });
              } else {
                Swal.close();
                console.log(e);
                this.snackbar = true;
                this.text = "Error al ingresar los datos. Error: " + e.message;
                this.color = "error";
              }
            } else {
              Swal.close();
              Swal.alertError(
                "Error de Autenticación",
                "Acceso inválido. No puede finalizar el trámite"
              );
            }
          });

          break;

        case "7":
          Swal.fire({
            title: "¡ATENCIÓN!",
            html: "<p>Los datos del trámite serán enviados a la DNRPA y no podrán ser modificados nuevamente</p><p><strong>¿Desea continuar?</strong></p>",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#008000",
            cancelButtonColor: "#d33",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({
                name: "f01_autos_pdf",
                params: {
                  id: this.editedItem.id,
                  tipo_certificado: this.editedItem.tipo_certificado,
                  numero_certificado: this.editedItem.numero_certificado,
                  chasis: this.editedItem.chasis,
                },
              });
            }
          });

          break;

        default:
          this.e6 = value;
          break;
      }
    },

    validar() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getPrivs() {
      if (this.$store.state.role_id == 1 || this.$store.state.role_id == 2) {
      }
    },

    close() {
      //this.dialog = false;
      this.reset();
      this.$emit("send-message");
    },

    async editData(id) {
      let titleAlert = "Buscando información del trámite ";
      Swal.alertGetInfo(titleAlert);

      await this.$axiosApi
        .getById("showFormulario", id)
        .then((r) => {
          //Swal.close();
          this.editedItem = r.data.data;
          //this.dialog = true;
          this.editIndex = id;
          //this.editIndex = this.editedItem.formulario.id;
          this.datosTramite = this.editedItem.formulario;
          this.cedulas = this.editedItem.formulario.cedulas;
          this.datosAutorizados = this.editedItem.autorizados;
          this.keyDatosTramite = this.keyDatosTramite + 1;
          this.datosTitulares = this.editedItem.titulares;
          //console.log(this.editedItem.titulares);
          this.titulares = Number(this.editedItem.formulario.titulares);
          //this.keyDatosTitulares = this.keyDatosTitulares + 1;
          this.datosSeguro = this.editedItem.seguro;
          //this.keyDatosSeguro = this.keyDatosSeguro + 1;

          this.datosVehiculo = this.editedItem.vehiculo;
          //this.keyDatosVehiculo = this.keyDatosVehiculo + 1;
          this.datosAdquisicion = this.editedItem.adquisicion;
          //this.keyDatosAdquisicion = this.keyDatosAdquisicion + 1;
          this.datosRegistro = this.editedItem.registro;
          //this.keyDatosRegistro = this.keyDatosRegistro+1;
        })
        .catch(function (error) {
          //Swal.close();
          this.$respuesta(this, error, 3, true);
        });
    },
  },
  created() {
    if (this.$route.params.id > 0) {
      this.editData(
        this.$route.params.id,
        this.$route.params.tipo_certificado,
        this.$route.params.numero_certificado,
        this.$route.params.chasis
      );
    }
  },
  mounted() {
    console.log("Componente F01 Wizard montado");
  },
};
</script>
