import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
//import AxiosApi from "./plugins/axiosApi"

Vue.use(Vuex)


export default new Vuex.Store({
  plugins:[
    createPersistedState()
  ],
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    /*barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',*/
    barImage: '',
    drawer: null,
    authenticated:false,
    user:{
      name:null,            
      role:null,
      role_id:null,      
      permisos:null,
    },
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_USER (state, payload){
      state.user.name = payload.name;      
      state.user.role = payload.role;
      state.user.role_id = payload.role_id;            
      //state.user.termsAccepted = payload.termsAccepted;
      state.user.permisos = payload.permisos;
    },
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },
  },
  actions: {
    logout({commit}){
        commit('SET_USER',{})
        commit('SET_AUTHENTICATED',false)
    },
    login({commit}){
      //console.log('login');
      //console.log(Vue.prototype.$token);
     
    },
  },
  getters : {
    isLogged: state => !!state.user,
    authenticated(state){
        return state.authenticated
    },
  }
})
